import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “The gym is so clean lots of play and educational stuff to play with, all the staffs are so friendly and so nice to the kids always smiling for sure I will bring back my grandson here.”                            </p>
                            <p className="overview">Rose P.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “We loved it here. The owners are very friendly and welcoming. Our child stayed and played for 2 hours and didn't want to leave. The value for your money is better than anywhere else we've been. Most importantly, knowing that any unexpected emotions and outbursts wouldn't be judged was relaxing. We can't wait to return.”                            </p>
                            <p className="overview">Aline A.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “My two sons absolutely love this place. The owners are super friendly. The place is very clean. We love coming here!"                            </p>
                            <p className="overview">
                            Shelby C. </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            “My son loves this place! He says "This place is like PARADISE!!" lol. He absolutely loves it, as do I! I love that they take cleanliness as seriously as they do & the calming room is very nice & is super helpful!! This location is also ran by the sweetest family, they're so kind & helpful. They love what they do & it makes it more enjoyable going to a location that is ran with love! Highly recommend giving this place a try if you haven't, you won't be disappointed!"                            </p>
                            <p className="overview">
                            Zayna S.</p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
